import Breadcrumb from '../../../../../components/breadcrumb'
import BaseChamadaPublica from '../../baseChamadaPublica'
import { listarFiltroUnidades, listarFiltroEscolaridades, listarInscricoesRemanejamento, listarTotalConvocados } from '../../../../../services/processos/chamadaPublica'
import { useNavigate } from 'react-router-dom'
import { useMemo, useEffect, useState } from 'react'
import { useQuery, useMutation } from 'react-query';
import urlsChamadaPublica from '../../urlsChamadaPublica'
import Table from '../../../../../components/table'
import { store } from '../../../../../store'
import { toast } from 'react-toastify'
import { getTurmasUnidadeEscolaridadeTurno } from '../../../../../services/turmas'


const ChamadaPublicInscritosListagemPageCrecheRemanejamento = (parametros) => {

  const navigate = useNavigate()

  const [totalConvocados, setTotalConvocados] = useState(0)
  const [totalRemanejamentoEntrada, setTotalRemanejamentoEntrada] = useState(0)
  const [totalRemanejamentoSaida, setTotalRemanejamentoSaida] = useState(0)

  let unidadeFiltro = localStorage.getItem('chamadaPublicaUnidadeFiltro') === null ? 0 : localStorage.getItem('chamadaPublicaUnidadeFiltro');
  let escolaridadeFiltro =  localStorage.getItem('chamadaPublicaEscolaridadeFiltro') === null ? 0 :  localStorage.getItem('chamadaPublicaEscolaridadeFiltro');
  let deficienteFiltro =  localStorage.getItem('chamaPublicaDeficienteFiltro') === null ? 0 :  localStorage.getItem('chamaPublicaDeficienteFiltro');
  let redeFiltro =  localStorage.getItem('chamaPublicaRedeFiltro') === null ? 0 :  localStorage.getItem('chamaPublicaRedeFiltro');
  let nomeFiltro = localStorage.getItem('chamaPublicaNomeFiltroFiltro') === null ? '' :  localStorage.getItem('chamaPublicaNomeFiltroFiltro');

  const [data, setData] = useState([])
  const [unidade, setUnidade] = useState(unidadeFiltro)
  const [escolaridade, setEscolaridade] = useState(escolaridadeFiltro)
  const [skip, setSkip] = useState(0);
  const [limit, setLimit] = useState(20);
  const [status, setStatus] = useState(1)
  const [deficiente, setDeficiente] = useState(deficienteFiltro)
  const [rede, setRede] = useState(redeFiltro)
  const [nome, setNome] = useState(nomeFiltro)
  const [turmasData, setTurmasData] = useState([])


const currentUser = store.getState()['user']['user']

  const [unidadesData, setUnidadesData] = useState([])
  const [escolaridadesData, setEscolaridadesData] = useState([])

  const {
    data: unidades,
    isLoading: loadingUnidades,
    refetch: refetchUnidades
  } = useQuery(
    'listarFiltroUnidades',
    () => listarFiltroUnidades(parametros.parametros),
    {
      retry: 3,
      enabled: true,
      onSuccess: data => {
        console.log(data)
        setUnidadesData(data)
      }
    }
  )

  async function refetchEscolaridades(unidadeId){
    if(parseInt(unidadeId) !== undefined && unidadeId !== 0){
      localStorage.setItem('chamadaPublicaUnidadeFiltro', parseInt(unidadeId));
      setUnidade(parseInt(unidadeId))
      var response =  await listarFiltroEscolaridades(parametros.parametros, unidade)
      var turmasResponse = await getTurmasUnidadeEscolaridadeTurno(unidade, escolaridade, 0, 2024, 0, 20)
      setTurmasData(turmasResponse)
      setEscolaridadesData(response)
    }
  }

  console.log(escolaridade)


  

  async function refetchInsricoes(escolaridadeId){
      if (parseInt(escolaridadeId) !== undefined){
        localStorage.setItem('chamadaPublicaEscolaridadeFiltro', parseInt(escolaridadeId));
        setEscolaridade(parseInt(escolaridadeId)) 
        var turmasResponse = await getTurmasUnidadeEscolaridadeTurno(unidade, escolaridadeId, 0, 2024, 0, 20)
        setTurmasData(turmasResponse)
        var response = await listarInscricoesRemanejamento(unidade, parseInt(escolaridadeId), status, deficiente, rede, skip,limit, nome)
        
        if(parametros.bloquearFiltros){
          const convocados = await listarTotalConvocados(unidade, parseInt(escolaridadeId))
          setTotalConvocados(convocados?.data?.totalConvocados)
          setTotalRemanejamentoEntrada(convocados?.data?.totalRemanejamentoEntrada)
          setTotalRemanejamentoSaida(convocados?.data?.totalRemanejamentoSaida)
        }
       
        setData(response)
        
      }
  }

  const statusArray = 
  [
    // { id: 0, value: 'SEM STATUS' },
    { id: 1, value: 'LISTA DE ESPERA' },
    { id: 2, value: 'CONVOCADO' },
    { id: 3, value: 'MATRICULADO' },
    { id: 4, value: 'NÃO COMPARECIDO' },
    { id: 5, value: 'INDEFERIDO' },
    { id: 6, value: 'CANCELADO' },
    { id: 7, value: 'DESISTÊNCIA' },
    { id: 8, value: 'MATRICULADO POR OUTRA OPÇÃO' },
    { id: 9, value: 'CONVOCADO POR OUTRA OPÇÃO' },
    { id: 10, value: 'MATRICULADO EM OUTRA OPÇÃO' }
  ]

  const filtroComuns = 
  [
    { id: 0, value: 'TODOS' },
    { id: 1, value: 'SIM' },
    { id: 2, value: 'NÃO' },
  ]
  
  useEffect(() => {

    // 

    // if (unidadesData.length == 0){
    //   refetchUnidades()
    // }

    if (unidade > 0 && escolaridadesData.length == 0){
      refetchEscolaridades(unidade)

    }

    if(nome.length >= 3){
      refetchInsricoes(escolaridade)
    }

    if (unidade > 0 && escolaridade > 0){
      refetchInsricoes(escolaridade)
    }
    

  }, [refetchUnidades, limit, skip, nome])

  const turmasColluns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Nome Turma', accessor: 'descricao' },
      { Header: 'PreMatricula', accessor: 'preMatricula' },
      { Header: 'Vagas Totais' , accessor: 'vagasTotais' },
      // { Header: 'Alunos', accessor: 'quantidadeAlunos' },
      // { Header: 'Sala (m²)', accessor: 'metragemSala' },
      // { Header: 'Ano Letivo', accessor: 'anoLetivo' },
      { Header: 'Vagas Regulares', accessor: 'vagas' },
      { Header: 'Vagas PcD', accessor: 'vagasPcd' },
      { Header: 'Alunos Regulares', accessor: 'quantidadeAlunosRegulares' },
      { Header: 'Alunos PcD', accessor: 'quantidadeAlunosPcdPne' },
      {
        Header: 'Saldo',
        accessor: 'saldo',
        Cell: ({ row }) => {

          let saldo = row.original.saldo;

          
          return (
            <span style={{
              color: saldo < 0 ? 'red' : 'inherit',
              fontWeight: saldo < 0 ? 'bold' : 'normal',
            }}>
              {saldo}
            </span>
          );
        },
      },
    ],
    [navigate]
  )

  const columns = useMemo(
    () => [
      { Header: '#', accessor: 'id' },
      { Header: 'Opção', accessor: 'opcaoEscolhidaNumeral' },     
      { Header: 'Nome', accessor: 'nome' },
      { Header: 'Responsável', accessor: 'responsavelNomeCompleto' },
      { Header: 'Escolaridade', accessor: 'escolaridade' },
      { Header: 'Escolaridade Descrição', accessor: 'escolaridadeDescricao' },
      { Header: 'Opção 1', accessor: 'opcao1' },
      { Header: 'Opcão 2', accessor: 'opcao2' },
      { Header: 'Opção 3', accessor: 'opcao3' },
      {
        Header: 'Nascimento', accessor: 'dataNascimento',
        Cell: ({ row }) => (
          row.original.dataNascimento.split('T')[0].split('-').reverse().join('/')
        )
      },
      {
        Header: 'Criação', accessor: 'criacao',
        Cell: ({ row }) => (
          row.original.criacao.split('T')[0].split('-').reverse().join('/') + ' ' + row.original.criacao.split('T')[1].split('.')[0]
        )
      },
      {
        Header: 'Status', accessor: 'statusOpcaoEscolhidaDescricao',
        Cell: ({ row }) => (
          <span className={`badge bg-dark`}>
            {row.original.statusOpcaoEscolhidaDescricao}
          </span>
        )
      },
      {
        Header: 'Deficiente', accessor: 'deficiente',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.deficiente == 1 ? 'success' : 'danger'}`}>
            {row.original.deficiente == 1 ? 'SIM' : 'NÃO'}
          </span>
        )
      },
      {
        Header: 'Estudante da Rede', accessor: 'estudanteDaRede',
        Cell: ({ row }) => (
          <span className={`badge bg-${row.original.estudanteDaRede == 1 ? 'success' : 'danger'}`}>
            {row.original.estudanteDaRede == 1 ? 'SIM' : 'NÃO'}
          </span>
        )
      },
      {
        Header: '',
        accessor: 'actions',
        Cell: ({ row }) => (
          <>

          <button
              onClick={() =>
                {  window.open(urlsChamadaPublica.visualizacao + row.original.id, '_blank')}
              }
              className="btn btn-sm btn-primary"
            >
              <i className="material-icons-two-tone">edit_note</i> visualizar
            </button> 
            
              <button onClick={() => navigate(urlsChamadaPublica.historico + '/' + row.original.id, {
                state: {
                  id: row.original.id,
                  unidade: row.original.opcaoEscolhidaNumeral,
                }
              })} className='btn btn-sm btn-warning'>
                <i className="material-icons-two-tone">edit_note</i> histórico
              </button>
              { parametros.bloquearFiltros && [257, 2429, 3029, 3030].includes(parseInt(currentUser?.servidorId)) && (
               <button onClick={() => navigate(urlsChamadaPublica.remanejamento + '/' + row.original.id, {
                state: {
                  id: row.original.id,
                  escolaridadeId: row.original.escolaridade,
                  escolaridadeDescricao: row.original.escolaridadeDescricao,
                  opcao1: row.original.opcao1,
                  opcao2: row.original.opcao2,
                  opcao3: row.original.opcao3,
                  nome: row.original.nome,
                }
              })} className='btn btn-sm btn-success'>
                <i className="material-icons-two-tone">edit_note</i> remanejamento
              </button>
              )}
          </>
        )
      },
      
    ],
    [navigate]
  )


return (
    <BaseChamadaPublica>
      <Breadcrumb
        title={'Inscrições - Remanejamento'}
        itens={['Chamada Pública', parametros.parametros == 1 ? 'Inscrições Creche' : 'Inscrições Pré', 'Lista Geral']}
        // hasExcelButton
      />


<div className="row" style={{ marginBottom: 20 }}>
      <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="unidade" className="form-label">
            Unidade
            </label>
            <select
              className="form-select"
              id="unidade"
              value={unidade}
              // disabled={parametros.bloquearFiltros}
              onChange={async e => {
                setEscolaridade(0)
                refetchEscolaridades(e.target.value)
              }}
            >
                <option value=""></option>
              {unidadesData?.map(unidade => (
                              <option key={unidade.id} value={unidade.id}>
                                {unidade.nome}
                              </option>
                            ))}
              
            </select>
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="escolaridade" className="form-label">
            Escolaridades
            </label>
            <select
              className="form-select"
              id="escolaridade"
              value={escolaridade}
              // disabled={parametros.bloquearFiltros}
              onChange={async e => {           
                refetchInsricoes(e.target.value)
                setEscolaridade(e.target.value)
              }}
            >

            <option value=""></option>
              {escolaridadesData?.map(item => (
                              <option key={item.id} value={item.id}>
                                {item.nome}
                              </option>
                            ))}
               
            </select>
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="status" className="form-label">
            Status
            </label>
            <select
              className="form-select"
              id="status" 
              value={status}
              disabled={parametros.bloquearFiltros}
              onChange={async e => {
                setStatus(e.target.value)
                // await localStorage.setItem('chamaPublicaStatusFiltro', e.target.value);
                refetchInsricoes(escolaridade)
              }}
            >

            <option value=""></option>
              {statusArray?.map(item => (
                              <option key={item.id} value={item.id}>
                                {item.value}
                              </option>
                            ))}
               
            </select>
          </div>
        </div>

      </div>

      <div className="row" style={{ marginTop: 20, marginBottom: 40 }}>
      <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="deficiente" className="form-label">
            Deficiente?
            </label>
            <select
              className="form-select"
              id="deficiente" 
              value={deficiente}
              disabled={parametros.bloquearFiltros}
              onChange={async e => {
                await setDeficiente(e.target.value)
                await localStorage.setItem('chamaPublicaDeficienteFiltro', e.target.value);
                refetchInsricoes(escolaridade)
              }}
            >

      {/* <option value={0}>s</option> */}
              {filtroComuns?.map(item => (
                              <option key={item.id} value={item.id}>
                                {item.value}
                              </option>
                            ))}
               
            </select>
          </div>
        </div>

        <div className="col-12 col-md-4">
          <div className="form-group">
            <label htmlFor="estudanteDaRede" className="form-label">
            Estudante Da Rede?
            </label>
            <select
              className="form-select"
              id="estudanteDaRede" 
              value={rede}
              disabled={parametros.bloquearFiltros}
              onChange={async e => {
                await setRede(e.target.value)
                await localStorage.setItem('chamaPublicaRedeFiltro', e.target.value);
                refetchInsricoes(escolaridade)
              }}
            >

            {/* <option value={0}></option> */}
              {filtroComuns?.map(item => (
                              <option key={item.id} value={item.id}>
                                {item.value}
                              </option>
                            ))}
               
            </select>
          </div>
        </div>

        <div className="col-12 col-md-12" style={{marginTop : '10px'}}>
        <div className='form-group'>
        <label htmlFor="regimeId" className="form-label">
              Pesquisar
            </label>
          <input
            type='text'
            className='form-control'
            placeholder='Pesquisar'
            value={nome}
            disabled={parametros.bloquearFiltros}
            onChange={e => setNome(e.target.value)}
          />
        </div>
        </div>

        {
  turmasData && turmasData.data && turmasData.data.length > 0 && parametros.bloquearFiltros && (
    <div className="row" style={{marginTop: 20}}>
      <div className="col-12">
        <h4>Dados Turmas</h4>
        <div className="card">
          <Table
            columns={turmasColluns}
            data={turmasData.data}
            hasPagination
            limit={limit}
            setLimit={setLimit}
            skip={skip}
            setSkip={setSkip}
            totalItems={turmasData.total}
            hiddenColluns={['preMatricula']}
          />
        </div>
      </div>
    </div>
  )
}

        <div className='row' style={{marginTop: 20}}>
          <div className='col-12'>
            {
              parametros.bloquearFiltros &&(            
                <h4>Inscrições Lista Espera | Total Convocados:  <b>{totalConvocados}</b> | Total Remanejamento Recebidos:  <b>{totalRemanejamentoEntrada}</b> | Total Remanejamento Enviados:  <b>{totalRemanejamentoSaida}</b></h4>
              )}
              {
              parametros.bloquearFiltros == false &&(            
                <h4>Inscrições</h4>
              )}
       
            <div className='card'>
              <Table
                columns={columns}
                data={data?.data ? data?.data : []}
                hasPagination
                limit={data?.limit}
                setLimit={setLimit}
                skip={data?.skip}
                setSkip={setSkip}
                totalItems={data?.total}
                hiddenColluns={['opcao1', 'opcao2', 'opcao3', 'escolaridade', 'escolaridadeDescricao']}
              />
            </div>
          </div>
        </div>

      </div>

   


      </BaseChamadaPublica>
)
}
export default ChamadaPublicInscritosListagemPageCrecheRemanejamento